<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          color="orange"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Editar perfil
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Completa tu perfil
            </div>
          </template>

          <v-form
            v-model="isValid"
            @submit="e => update(e)"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="form.ci"
                    disabled
                    label="Documento de indentidad"
                    class="purple-input"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="form.rif"
                    disabled
                    label="RIF"
                    class="purple-input"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="form.email"
                    disabled
                    :rules="emailRules"
                    label="Email"
                    class="purple-input"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.name"
                    class="purple-input"
                    label="Nombres"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.lastName"
                    class="purple-input"
                    label="Apellidos"
                    required
                  />
                </v-col>

                <v-col
                  cols="0"
                  sm="0"
                  xl="6"
                />

                <v-col
                  cols="6"
                  md="6"
                  xl="3"
                >
                  <v-btn
                    color="white"
                    elevation="0"
                    class="mr-5"
                    block
                    @click="dialog = true"
                  >
                    Cambiar contraseña
                  </v-btn>
                </v-col>

                <v-col
                  cols="4"
                  md="6"
                  xl="3"
                >
                  <v-btn
                    color="egrey"
                    class="ml-2"
                    block
                    type="submit"
                    :loading="fetchingUser"
                  >
                    Actualizar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="/Login.jpg"
        >
          <v-card-text class="text-center">
            <h3 class="text-h3 font-weight-light mb-3 black--text">
              {{ form.name }} {{ form.lastName }}
            </h3>
            <h5 class="grey--text">
              {{ form.email }}
            </h5>
            <h5 class="grey--text">
              {{ form.ci }}
            </h5>
            <h5 class="grey--text">
              {{ form.rif }}
            </h5>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card class="text-center">
        <v-card-title>
          Cambio de contraseña

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <br><br>

        <v-card-text>
          <v-form
            v-model="isValidPass"
            @submit="e => updatePass(e)"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="passForm.oldPassword"
                    :rules="passwordRules"
                    required
                    outlined
                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show ? 'text' : 'password'"
                    name="input-10-1"
                    label="Contraseña actual"
                    @click:append="show = !show"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="passForm.password"
                    :rules="passwordRules"
                    required
                    outlined
                    :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Nueva contraseña"
                    @click:append="show2 = !show2"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="repeatPass"
                    :rules="passwordRepeatRules"
                    required
                    outlined
                    :append-icon="show3 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Repita su contraseña"
                    @click:append="show3 = !show3"
                  />
                </v-col>
                <v-col
                  v-if="!equalPass"
                  class="p-0"
                >
                  <p
                    class="red--text"
                    :style="{
                      fontSize: '12pt'
                    }"
                  >
                    Las contraseñas no coinciden
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="mb-5"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    block
                    type="submit"
                    :disabled="(passForm.password === '' && repeatPass === '' ) || !equalPass || !passForm.oldPassword"
                    :loading="fetchingResetPassword"
                  >
                    Enviar &nbsp; <v-icon>mdi-send</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="mb-1" />
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  // import pageCondicionerMixin from '@/mixins/pageCondicionerMixin'
  // import { ADMIN, USER, CLIENT } from '@/const'
  export default {
    name: 'UserProfileView',

    // mixins: [pageCondicionerMixin([ADMIN, USER, CLIENT])],

    data () {
      return {
        show: false,
        show2: false,
        show3: false,
        dialog: false,
        isValid: false,
        isValidPass: false,
        emailRules: [
          v => !!v || 'El email es requerido',
          v => /.+@.+/.test(v) || 'Debe ingresar un email valido',
        ],
        nameRules: [
          v => !!v || 'El nombre es requerido',
        ],
        passwordRules: [
          v => !!v || 'La contraseña es requerido',
        ],
        form: {
          ci: '',
          rif: '',
          name: '',
          lastName: '',
          email: '',
        },
        repeatPass: '',
        passForm: {
          oldPassword: '',
          password: '',
        },
      }
    },

    computed: {
      ...mapState(['user', 'fetchingUser', 'fetchingResetPassword']),
      equalPass () {
        return this.passForm.password === this.repeatPass
      },

      passwordRepeatRules () {
        return [
          v => !!v || 'La contraseña es requerido',
        ]
      },
    },

    created () {
      this.form = { ...this.user }
    },

    methods: {
      ...mapActions(['setPassword', 'updateProfile']),
      update (e) {
        e.preventDefault()

        if (this.isValid) {
          this.updateProfile({ name: this.form.name, lastName: this.form.lastName })
        }
      },
      updatePass (e) {
        e.preventDefault()

        if (this.isValid) {
          this.setPassword(this.passForm)
          this.dialog = false
          this.passForm = {
            oldPassword: '',
            password: '',
          }
        }
      },
    },
  }
</script>
